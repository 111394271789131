import "core-js/modules/es.array.push.js";
import { editCourse, getGradeAll, getEvaOption, courseInfoById, getIndustryList } from "@/api/api";
export default {
  name: "index",
  data() {
    return {
      grades: [],
      course_id: '',
      showInfo: false,
      industryList: [],
      Info: {
        id: '',
        name: '',
        code: '',
        type: '',
        industry: '',
        course_links: [{
          name: '',
          course_link_student: [],
          course_link_teacher: [],
          course_link_eva: [],
          course_link_teacher_visible: false,
          course_link_teacher_value: '',
          course_link_teacher_index: -1,
          //教师 被选中的 标签
          course_link_teacher_content: '',
          course_link_student_visible: false,
          course_link_student_value: '',
          course_link_student_index: -1,
          //学生 被选中的 标签
          course_link_student_content: ''
        }]
      },
      optionList: [],
      rules: {
        name: [{
          required: true,
          message: "请输入课程名称",
          trigger: 'blur'
        }]
      }
    };
  },
  mounted() {
    this.getGradeList();
    this.getIndustryList();
    this.getEvaOption();
    let data = this.$root.useRoute.query;
    if (data.id) {
      this.course_id = data.id;
      this.getInfo();
    }
    if (data.showInfo == 1) {
      this.showInfo = true;
    }
  },
  methods: {
    qxBtn() {
      this.$root.useRouter.go(-1);
    },
    areaChange(e) {
      this.Info.province = e[0];
      this.Info.city = e[1];
      this.Info.area = e[2];
    },
    getIndustryList() {
      getIndustryList().then(res => {
        this.industryList = res.data;
      });
    },
    getIndustryName(arr) {
      let text = '';
      if (arr && arr[0]) {
        this.industryList.find(res => {
          if (res.value == arr[0]) {
            text = res.label;
            if (res.children && arr[1]) {
              res.children.find(res1 => {
                if (res1.value == arr[1]) {
                  text = text + '/' + res1.label;
                  return text;
                }
              });
            }
            return text;
          }
        });
      }
      return text;
    },
    getInfo() {
      courseInfoById({
        id: this.course_id
      }).then(res => {
        this.Info = res.data;
        if (this.Info.industry) {
          this.Info.industry = JSON.parse(this.Info.industry);
        }
        if (this.Info.grade) {
          this.grades = this.Info.grade.split(',');
          this.grades = this.grades.map(function (data) {
            return +data;
          });
        }
        if (this.Info.course_links) {
          for (let t in this.Info.course_links) {
            this.Info.course_links[t] = {
              name: this.Info.course_links[t].name,
              course_link_student: JSON.parse(this.Info.course_links[t].course_link_student),
              course_link_teacher: JSON.parse(this.Info.course_links[t].course_link_teacher),
              course_link_eva: this.Info.course_links[t].course_link_eva,
              course_link_teacher_visible: false,
              course_link_teacher_value: '',
              course_link_teacher_index: -1,
              //教师 被选中的 标签
              course_link_teacher_content: '',
              course_link_student_visible: false,
              course_link_student_value: '',
              course_link_student_index: -1,
              //学生 被选中的 标签
              course_link_student_content: ''
            };
          }
        }
      });
    },
    getGradeList() {
      getGradeAll().then(res => {
        this.$nextTick(() => {
          this.gradeList = res.data;
        });
        //this.$forceUpdate();
        //this.$nextTick
      });
    },
    getEvaOption() {
      getEvaOption().then(res => {
        this.optionList = res.data;
        /*        let teacherTips=[];
                let systemTips=[];
                for (let l in list){
                  let teacherTip={value:list[l].id,label:list[l].name,children:[]};
                  let systemTip={value:list[l].id,label:list[l].name,children:[]};
                  for (let i in list[l].teacherTips){
                    teacherTip.children.push({value:list[l].teacherTips[i].id,label:list[l].teacherTips[i].name});
                  }
                  for (let i in list[l].systemTips){
                    systemTip.children.push({value:list[l].systemTips[i].id,label:list[l].systemTips[i].name});
                  }
                  teacherTips.push(teacherTip);
                  systemTips.push(systemTip);
                  this.teacherTipsList=teacherTips;
                  this.systemTipsList=systemTips;
                }*/
      });
    },
    subData() {
      //提交
      this.$refs['Info'].validate(valid => {
        if (valid) {
          if (this.grades.length > 0) {
            this.Info.grade = this.grades.join(',');
            //this.$root.ElMessage.error('请选择适用年级');
            //return false;
          }
          editCourse(this.Info).then(res => {
            if (res.success) {
              this.$root.ElMessage({
                type: 'success',
                message: res.msg
              });
              this.$root.useRouter.go(-1);
            } else {
              this.$root.ElMessage.error(res.msg);
            }
          });
        }
      });
    },
    setText() {
      this.$refs.editor.setText(this.Info.text);
    },
    getText(html) {
      this.Info.text = html;
    },
    add_link() {
      //增加环节
      let s = {
        name: '',
        course_link_student: [],
        course_link_teacher: [],
        course_link_eva: [],
        course_link_teacher_visible: false,
        course_link_teacher_value: '',
        course_link_teacher_index: -1,
        //教师 被选中的 标签
        course_link_teacher_content: '',
        course_link_student_visible: false,
        course_link_student_value: '',
        course_link_student_index: -1,
        //学生 被选中的 标签
        course_link_student_content: ''
      };
      this.Info.course_links.push(s);
    },
    showEvaInput(c, key, optionId) {
      c['course_link_eva_visible'][optionId] = true;
    },
    showInput(type, c, key) {
      c[type + '_visible'] = true;
      console.log(type + '_ref' + key);
      this.$nextTick(() => {
        this.$refs[type + '_ref' + key].focus();
      });
    },
    handleInputConfirm(type, c) {
      if (c[type + '_value']) {
        c[type].push({
          name: c[type + '_value'],
          content: ''
        });
      }
      c[type + '_visible'] = false;
      c[type + '_value'] = '';
      let l = c[type].length - 1;
      this.checkTag(l, type, c);
    },
    handleClose(index, type, c) {
      c[type].splice(index, 1);
    },
    checkTag(index, type, c) {
      c[type + '_index'] = index;
      c[type + '_content'] = c[type][index].content;
      this.$forceUpdate();
    },
    saveContent(type, c) {
      c[type][c[type + '_index']].content = c[type + '_content'];
    }
  }
};